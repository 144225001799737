//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import Logo from '../../../components/Logo.vue';

export default {
  name: 'CreateAccount',

  components: { Logo },

  props: {
    loader: {
      type: Boolean,
      default: false,
    },
    form: {
      type: Object,
      required: true,
    },
    rules: {
      type: Object,
      default: () => {},
    },
    defaultCurrencies: {
      type: Array,
      default: () => [],
    },
    regions: {
      type: Array,
      default: () => [],
    },
  },

  data() {
    return {
      editMode: false,
    };
  },

  computed: {
    regionName() {
      switch (this.form.regionId) {
      case 1: return 'Indonesia';
      case 2: return 'Europe';
      default: return 'Asia';
      }
    },
  },

  methods: {
    toggleEdit() {
      this.editMode = !this.editMode;
    },
    handleValidateForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (!valid) {
          return;
        }

        this.$emit('submit');
      });
    },
  },
};

