//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import http from '../../../utils/http';
import Logo from '../components/Logo.vue';

export default {
  name: 'ForgotPassword',

  components: {
    Logo,
  },

  data() {
    const self = this;
    return {
      isSubmit: false,

      loader: false,

      form: {
        username: '',
      },

      rules: {
        username: [
          { required: true, message: self.$t('validations.required', { value: self.$t('fields.email') }), trigger: 'change' },
          { type: 'email', message: self.$t('validations.valid', { value: self.$t('fields.email') }), trigger: 'change' },
        ],
      },
    };
  },

  methods: {
    resetField() {
      this.isSubmit = false;
      this.form.username = '';
      this.token = null;
    },

    handleSubmit(formName) {
      this.$refs[formName].validate((valid) => {
        if (!valid) {
          return;
        }

        // Show spinner
        this.loader = true;

        // add recaptcha
        this.$recaptchaLoaded()
          .then(() => this.$recaptcha('signup'))
          .then(token => http.v1.post('signups', {
            email: this.form.username,
            gToken: token,
          }))
          .then(() => {
            this.isSubmit = true;
            localStorage.removeItem('sign_up_started');

            const { track } = this.$telemetry;

            track('user_signed_up', { uiArea: 'sign_up_page' });

            localStorage.setItem('sign_up_started', new Date());
            this.submitHubspotForm();
          })
          .catch((err) => {
            this.$showError(this, err, { reloadUrl: '/login/signup' });
            // if (window.Bugsnag) {
            //   window.Bugsnag.notify(err);
            // }
            // const message = this.$t('errors.default');

            // this.$message({
            //   title: 'Error',
            //   message: (err && err.message) || message,
            //   type: 'error',
            // });
          })
          .finally(() => {
            this.loader = false;
          });
      });
    },
    emailForm() {
      this.form.username = '';
      this.isSubmit = false;
    },
    login() {
      this.$router.push({ path: '/' });
    },
    submitHubspotForm() {
      const {
        username: email,
      } = this.form;

      http.post('https://forms.hubspot.com/uploads/form/v2/587546/af4548c6-a415-4951-bd6a-5a22ea2fb06a', {
        email,
      }, {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
      })
        .then(() => {})
        .catch((err) => {
          if (window.Bugsnag) {
            window.Bugsnag.notify(err);
          }
        });
    },
  },
};
