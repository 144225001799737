<template>
  <el-form
    ref="passwordForm"
    :model="form"
    :rules="rules"
    @submit.native.prevent="handleValidateForm('passwordForm')"
  >
    <div class="text-center mb-12">
      <Logo />
    </div>
    <h2 class="text-black text-lg mb-5">
      {{ $t('user.sign_up') }}
    </h2>

    <!-- Email Address -->
    <div class="block">
      <p class="mb-1 text-sm font-medium text-grey-darker">
        {{ $t('fields.email') }}
      </p>
      <el-form-item>
        <el-input
          id="email"
          :value="form.email"
          disabled="false"
        >
          <template #suffix>
            <img
              src="../../../../assets/check.svg"
              alt="Check"
              width="24"
              class="h-full"
            >
          </template>
        </el-input>
      </el-form-item>
    </div>

    <!-- Password -->
    <div class="block">
      <p class="mb-1 text-sm font-medium text-grey-darker">
        {{ $t('fields.choose_password') }}
      </p>
      <el-form-item prop="password">
        <el-input
          id="password"
          v-model="form.password"
          :placeholder="$t('user.password_placeholders[3]')"
          type="password"
          @keyup.native="check"
        />
      </el-form-item>
      <p class="text-xs">
        {{ $t('user.password_reminder') }}
      </p>
    </div>

    <!-- Password Strength -->
    <div class="w-full mt-4">
      <el-form-item class="mb-2">
        {{ $t('user.password_labels[3]') }}
        <el-progress
          :show-text="false"
          :percentage="strength"
          :status="status"
        />
      </el-form-item>
    </div>

    <!-- Marketing -->
    <el-form-item class="mb-0">
      <el-checkbox
        v-model="form.subscribe"
        class="break-all mt-5 block font-normal whitespace-normal flex"
        size="small"
      >
        {{ $t('user.marketing_opt_in') }}
      </el-checkbox>
    </el-form-item>

    <!-- Terms and Conditions -->
    <el-form-item>
      <el-checkbox
        v-model="form.acceptTermsConditions"
        class="break-all mt-5 block font-normal whitespace-normal flex"
        size="small"
      >
        {{ $t('user.terms_conditions[0]') }} <span
          class="text-red"
          @click="gotoTermsAndConditions"
        >Terms and Conditions</span> {{ $t('user.terms_conditions[1]') }}
      </el-checkbox>
    </el-form-item>

    <el-form-item>
      <el-button
        :loading="loader"
        :disabled="!form.email || !form.acceptTermsConditions"
        type="primary"
        native-type="submit"
        class="w-full mt-4"
      >
        {{ $t('actions.continue') }}
      </el-button>
    </el-form-item>
  </el-form>
</template>

<script>
import Logo from '../../../components/Logo.vue';

export default {
  name: 'ChoosePassword',

  components: {
    Logo,
  },

  props: {
    loader: {
      type: Boolean,
      default: false,
    },
    form: {
      type: Object,
      required: true,
    },
    rules: {
      type: Object,
      default: () => {},
    },
  },

  data() {
    return {
      score: 0,
    };
  },

  computed: {
    strength() {
      return this.score * 25;
    },
    status() {
      if (this.score < 3) {
        return 'weak';
      }

      if (this.score < 4) {
        return 'good';
      }

      return 'strong';
    },
  },

  methods: {
    gotoTermsAndConditions() {
      window.open('https://www.8x8.com/terms-and-conditions');
    },
    check() {
      const result = this.$zxcvbn3(this.form.password);
      this.score = result.score;
    },
    handleValidateForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (!valid) {
          return;
        }

        this.$emit('next-form');
      });
    },
  },
};
</script>


<style>
.el-checkbox__input {
  margin-top: 0.2rem;
}
</style>
