<!-- eslint-disable camelcase -->
<template>
  <section class="w-full h-full">
    <div v-if="loggedInLoading">
      <div
        v-loading="Boolean(true)"
        :element-loading-text="$t('wait.verifying')"
        class="w-full h-full min-h-screen"
      >
        &nbsp;
      </div>
    </div>
    <div
      v-else
      class="w-full h-full flex flex-col items-center"
    >
      <div class="w-1/2 lg:flex justify-center flex-col flex-shrink-1 items-center">
        <div class="p-10">
          <h1 class="text-center xs:text-lg sm:text-xl md:text-2xl lg:text-4xl text-black leading-normal">
            {{ $t('user.welcome') }}
          </h1>
          <p class="xs:text-xs sm:text-xs md:text-xs lg:text-sm xl:text-base text-grey-darker text-center mt-5 pl-2">
            {{ $t('user.subwelcome') }}
          </p>
        </div>
      </div>
      <div class="shadow bg-white flex-shrink-0 xs:w-3/4 sm:w-3/4 md:w-1/2 max-w-md">
        <div class=" overflow-auto xs:py-5 sm:py-10 md:py-10 lg:py-16 flex flex-col items-center">
          <el-form
            id="login-btn"
            ref="formRef"
            label-position="top"
            :model="form"
            :rules="rules"
            class="form-container w-3/4"
            @submit.native.prevent="login"
          >
            <div class="text-center mb-12">
              <Logo />
            </div>
            <h2 class="text-black text-lg mb-5">
              {{ $t('user.login') }}
            </h2>
            <div class="block">
              <p class="mb-1 text-sm font-medium text-grey-darker">
                {{ $t('fields.email') }}
              </p>
              <el-form-item prop="username">
                <el-input
                  id="email"
                  v-model="form.username"
                  :placeholder="$t('user.placeholders[0]')"
                />
              </el-form-item>
            </div>

            <div class="block">
              <div class="mb-1 flex justify-between">
                <p class="text-sm font-medium text-grey-darker">
                  {{ $t('fields.password') }}
                </p>
                <router-link
                  tabindex="-1"
                  to="/forgot-password"
                  class="text-sm text-blue no-underline inline-block"
                >
                  {{ $t('user.forgot_password') }}
                </router-link>
              </div>
              <el-form-item
                prop="password"
              >
                <el-input
                  id="password"
                  v-model="form.password"
                  :placeholder="$t('user.password_placeholders[3]')"
                  type="password"
                />
              </el-form-item>
            </div>

            <el-form-item class="mb-4">
              <el-button
                type="primary"
                class="login-btn w-full"
                :disabled="loginLoading"
                :loading="loginLoading"
                native-type="submit"
              >
                {{ $t('user.login') }}
              </el-button>
            </el-form-item>

            <div class="flex items-center">
              <hr class="flex-1">
              <span class="mx-2 text-sm">OR</span>
              <hr class="flex-1">
            </div>

            <el-form-item class="m-0">
              <div class="flex justify-center">
                <el-link
                  type="primary"
                  :underline="false"
                  :disabled="loginLoading"
                  @click="goToSSOLogin"
                >
                  {{ $t('user.sso') }}
                </el-link>
              </div>
            </el-form-item>

            <router-link
              to="/signup"
              class="mt-12 flex justify-center text-grey-darker no-underline text-xs inline-block"
            >
              {{ $t('user.asking_account[0]') }}
              &nbsp;
              <span class="text-blue">{{ $t('user.asking_account[2]') }}</span>
            </router-link>
          </el-form>
        </div>
      </div>
    </div>
    <el-dialog
      :title="$t('user.human_verification')"
      :visible="showCaptchaDialog"
      width="33%"
      :close-on-click-modal="Boolean(false)"
      :show-close="Boolean(false)"
      @open="handleCaptchaDialogOpen"
    >
      <div
        ref="captchaBox"
        v-loading="cLoading"
        :style="{minHeight: '300px'}"
      />
    </el-dialog>
  </section>
</template>

<script>

import jwtDecode from 'jwt-decode';
import { navigateToUrl } from 'single-spa';
import { mapActions, mapGetters } from 'vuex';
import { v4 as uuidv4 } from 'uuid';
import env from '../../../utils/process_env';
import utils from '../../support/utils';
import Logo from '../components/Logo.vue';
// eslint-disable-next-line import/no-extraneous-dependencies


export default {
  name: 'Login',

  components: {
    Logo,
  },

  data() {
    const self = this;
    return {
      loginLoading: false,
      loginResponse: {},
      form: {
        username: '',
        password: '',
      },
      rules: {
        username: [
          {
            type: 'string',
            required: true,
            message: self.$t('validations.valid', { value: self.$t('fields.email').toLowerCase() }),
            pattern: /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/,
            trigger: 'blur',
          },
        ],
        password: [
          {
            type: 'string',
            required: true,
            message: self.$t('validations.required', { value: self.$t('fields.password') }),
            trigger: 'blur',
          },
        ],
      },
      showCaptchaDialog: false,
      cLoading: true,
    };
  },

  beforeRouteEnter: (to, from, next) => {
    if (to.query && to.query.err) {
      next(vm => vm.showSSOErrorMessage(to.query.err));
    }
    next();
  },

  computed: {
    ...mapGetters({
      loggedInLoading: 'getLoggedInLoading',
    }),
  },

  methods: {
    ...mapActions({
      getAuthUser: 'common/getAuthUser',
      verifySSO: 'common/verifySSO',
      loginSSO: 'common/loginSSO',
      loginUser: 'common/login',
      addSubscriptionInfoToCache: 'common/addSubscriptionInfoToCache',
    }),

    handleCaptchaDialogOpen() {
      this.showAwsWafCaptcha();
    },

    showAwsWafCaptcha() {
      this.$nextTick(() => {
        if (this.$refs.captchaBox && window.AwsWafCaptcha) {
          window.AwsWafCaptcha.renderCaptcha(this.$refs.captchaBox, {
            apiKey: env.awsWAFRecaptchaKey,
            onSuccess: this.onAwsWafCaptchaSuccess,
            onLoad: this.onAwsWafCaptchaOnLoad,
            onError: this.onAwsWafCaptchaError,
          });
        }
      });
    },

    onAwsWafCaptchaOnLoad() {
      this.cLoading = false;
    },

    onAwsWafCaptchaSuccess() {
      if (this.$refs.captchaBox) {
        this.$refs.captchaBox.innerHTML = '';
      }
      this.showCaptchaDialog = false;
      this.login();
    },

    onAwsWafCaptchaError(err) {
      window.Bugsnag.notify(err);
      this.cLoading = false;
      // this.$message.error(err.message);
    },

    setTelemetryGroupAndUser(user = {}) {
      const {
        telemetryClient, setUpTelemetry, teardownTelemetry, clientEventBusConfig, serviceConfig, serviceEventBusConfig,
      } = this.$telemetry;

      const {
        AccountId: accountId,
        UserId: userId,
        AccountName: accountName,
        IsPostPaid: isPostPaid,
        Email: email,
        RegionId: regionId,
        // eslint-disable-next-line camelcase
        Product_AT: product_automation,
        // eslint-disable-next-line camelcase
        Product_VO: product_voice,
        // eslint-disable-next-line camelcase
        Product_VI: product_video,
        // eslint-disable-next-line camelcase
        Product_SUBSCRIPTION: product_subscription,
        // eslint-disable-next-line camelcase
        Product_SMS: product_sms,
        // eslint-disable-next-line camelcase
        Product_CA: product_messaging_apps,
        // eslint-disable-next-line camelcase
        BetaFeatures: beta_features,
      } = user;

      // reset  telemetry and initialize new instance
      teardownTelemetry();

      serviceConfig.userId = userId;
      serviceEventBusConfig.scopeId = uuidv4();
      clientEventBusConfig.scopeId = serviceEventBusConfig.scopeId;

      setUpTelemetry({ serviceEventBusConfig, serviceConfig, clientEventBusConfig });

      // set new group
      telemetryClient.setGroup('Connect Account', 'Connect', {
        account_id: accountId,
        account_name: accountName,
        acccount_plan_type: isPostPaid ? 'Postpaid' : 'Prepaid',
        product_automation,
        product_voice,
        product_video,
        product_subscription,
        product_sms,
        product_messaging_apps,
        beta_features,
        region_id: regionId,
      });

      // set new user props
      telemetryClient.setUserProperties({
        email: email.replace(/^(.{2})[^@]+/, '$1****'),
        view_port_height: window.innerHeight,
        view_port_width: window.innerWidth,
        screen_resolution_height: window.screen.height,
        screen_resolution_width: window.screen.width,
      });
    },

    login() {
      const self = this;

      const handleSuccess = async (response = {}) => {
        const {
          status,
          TwoFA: tf,
          RefId: refId,
          authorization,
        } = response;
        if (status && status === 'success') {
          // Set authorization token in local storage
          localStorage.setItem('WWW-Authenticate', authorization);

          // singleSpa.navigateToUrl('/');
          try {
            const user = jwtDecode(authorization);
            await this.addSubscriptionInfoToCache({
              accountId: user.AccountId,
            });

            const { data } = await this.getAuthUser();

            localStorage.setItem('CPV3_User', JSON.stringify(data));
            // // segment tracking
            // if (window.analytics) {
            //   window.analytics.identify(data.Email, {
            //     Email: data.Email,
            //     AccountUid: data.AccountUid,
            //     SelectedLocale: localStorage.getItem('SELECTED_LOCALE'),
            //   });
            // }

            this.setTelemetryGroupAndUser(data);

            if (window.Bugsnag) {
              window.Bugsnag.setUser(data.UserId);
            }

            const { redirect } = this.$route.query;

            if (redirect) {
              const validUrl = this.isValidUrl(redirect);

              if (validUrl) {
                const h = this.$createElement;
                this.$msgbox({
                  title: self.$t('user.page_redirect'),
                  message: h('div', {}, [
                    h('p', {}, self.$t('user.leaving_connect')),
                    h('p', {}, `${redirect}`),
                  ]),
                  cancelButtonText: self.$t('actions.proceed'),
                  showCancelButton: true,
                  confirmButtonText: self.$t('actions.cancel'),
                  // reverse implementation for UI suggested color scheming
                  // Cancel == response:Confirm
                  // Confirm == response.Redirect:Default
                }).then(() => {
                  window.location = '/';
                }) // eslint-disable-line no-unused-vars
                  .catch((err) => {
                    if (err.indexOf('cancel') >= 0) {
                      window.location = '/';
                    } else {
                      navigateToUrl(redirect);
                    }
                  });
              } else {
                window.location = '/';
              }
            } else {
              window.location = '/';
            }
          } catch (err) {
            this.$showError(this, err);
          }
        } else if (tf && tf.length && refId) {
          localStorage.setItem('OTP-VERIFY', JSON.stringify(response));

          this.$router.push({ path: '/verify' });
        } else {
          throw new Error(this.$t('errors.default'));
        }

        return 0;
      };


      const loginWithEmailAndPassword = async () => {
        const response = await self.loginUser({
          ...this.form,
          rolesVersion: 2,
        });

        handleSuccess(response);
      };

      const loginWithSSO = async () => {
        const { idpLoginUrl } = await self.loginSSO({ email: self.form.username });
        const url = new URL(idpLoginUrl);
        window.location.replace(url.href);
      };

      this.$refs.formRef.validate(async (valid) => {
        if (!valid) {
          return;
        }

        try {
          this.loginLoading = true;

          await this.$recaptchaLoaded();
          const token = await this.$recaptcha('verify_sso');

          const { SAMLConfig } = await this.verifySSO({ username: this.form.username, gToken: token });

          if (SAMLConfig) {
            await loginWithSSO();
            return;
          }

          await loginWithEmailAndPassword();
        } catch (err) {
          if ((err && err.status && err.status === 405) || (err.data && err.data.status && err.data.status === 405)) {
            window.Bugsnag.notify(err);
            this.showCaptchaDialog = true;
            return;
          }

          if (err.message === 'User suspended') {
            this.$showError(this, err, { userMessage: this.$t('errors.account_suspended'), isLogin: true });
          } else {
            this.$showError(this, err, { isLogin: true });
          }
        } finally {
          self.loginLoading = false;
        }
      });
    },

    showMessage(message, type) {
      if (message === 'User suspended') {
        const msg = this.$t('errors.account_suspended');
        this.$message({
          showClose: true,
          msg,
          type: 'error',
        });
      } else {
        this.$message({
          showClose: true,
          message,
          type,
        });
      }
    },

    showSSOErrorMessage(err) {
      // USER_NOT_FOUND - User not found
      // USER_SUSPENDED - User suspended
      // USER_UNAUTHORIZED - User not authorized
      // SAML_NOT_CONFIGURED - SAML is not configured
      // SAML_CONFIGURATION_ERROR - SAML configuration error
      // SAML_NOT_SUPPORTED - SAML request is not supported
      // SAML_FORMAT_INVALID - SAML response format is not valid
      // SAML_DISABLED - SAML has been disabled

      let message = '';

      switch (err) {
      case 'USER_NOT_FOUND': {
        message = this.$t('errors.saml_user_not_found');
        break;
      }

      case 'USER_SUSPENDED': {
        message = this.$t('errors.saml.user_suspended');
        break;
      }

      case 'USER_UNAUTHORIZED': {
        message = this.$t('errors.saml.user_unauthorized');
        break;
      }

      case 'SAML_NOT_CONFIGURED': {
        message = this.$t('errors.saml.not_configured');
        break;
      }

      case 'SAML_CONFIGURATION_ERROR': {
        message = this.$t('errors.saml_config_error');
        break;
      }

      case 'SAML_NOT_SUPPORTED': {
        message = this.$t('errors.saml_not_supported');
        break;
      }

      case 'SAML_FORMAT_INVALID': {
        message = this.$t('errors.saml_format_invalid');
        break;
      }

      case 'SAML_DISABLED': {
        message = this.$t('errors.saml.disabled');
        break;
      }

      default: {
        message = this.$t('errors.sso_failed');
      }
      }

      this.$msgbox({
        title: this.$t('user.sso_failed'),
        message,
        type: 'error',
      });
    },

    forgotPassword() {
      this.$router.push({ path: '/forgot-password' });
    },

    goToSSOLogin() {
      this.$router.push({ path: '/sso' });
    },

    isValidUrl(url) {
      let newUrl;
      try {
        newUrl = utils.inPlaceSanitize(url);
        if (newUrl === '/') {
          return false;
        }
        return new URL(newUrl);
      } catch (err) {
        return false;
      }
    },
  },
};
</script>

<style>
 .form-container .el-form-item__label {
    padding: 0;
 }

 .backdrop-l {
   backdrop-filter: blur(10px);
 }
</style>
