import axios from 'axios';
import qs from 'query-string';

import Bugsnag from '@bugsnag/js';

// List of endpoints that is region based
const regionalEndpointList = [
  '^/balance',
  '^/accounts/([-a-zA-Z0-9]*)/message-balance$', // get account message balance
];

// Decode jwt token
const decodeJwt = (token) => {
  if (!token) {
    return 0;
  }

  try {
    const base64Url = token.split('.')[1];
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    const jsonPayload = decodeURIComponent(atob(base64).split('').map(c => `%${(`00${c.charCodeAt(0).toString(16)}`).slice(-2)}`).join(''));

    const decodedData = JSON.parse(jsonPayload);
    return decodedData;
  } catch (err) {
    console.error('Failed to validate region');
    return 0;
  }
};

// common interceptors configs
const responseSuccessConfig = (response) => {
  if (response.headers && response.headers.authorization) {
    localStorage.setItem('WWW-Authenticate', response.headers.authorization);
  }

  return response;
};
const responseErrorConfig = err => Promise.reject((err && err.response) || {});
const requestSuccessConfig = (c, version = 'v1') => {
  const config = c;
  try {
    const token = localStorage.getItem('WWW-Authenticate');

    if (token) {
      config.headers['Authorization'] = `Bearer ${token}`; // eslint-disable-line
    }

    const { url } = config;
    const noQueryUrl = `/${url.split('?')[0]}`; // Only extract the endpoints
    const isGeoApi = regionalEndpointList.find(apiRegExp => new RegExp(apiRegExp).test(noQueryUrl));

    // Determine if endpoint is part of `regionalEndpointList`
    if (isGeoApi) {
      let regionId = '';

      // Decode jwt and determine region based on RegionId
      const decodedData = decodeJwt(token);

      if (decodedData && Object.keys(decodedData).includes('RegionId')) {
        const { RegionId: region } = decodedData;
        regionId = region;

        if (![0, 1, 2].includes(regionId)) {
          if (window.Bugsnag) {
            window.Bugsnag.notify(new Error('Region Id is invalid or undefined'), (event) => {
              event.severity = 'error';
              event.addMetadata('user', {
                id: decodedData.UserId,
                Email: decodedData.Email,
                RegionId: decodedData.RegionId,
              });
            });
          }
        }
      }

      // Change baseUrl based on region
      switch (regionId) {
      // Indonesia region
      case 1:
        config.baseURL = `${process.env.VUE_APP_API_URL_ID || 'https://portal.id.wavecell.dev'}/api/${version}`; // eslint-disable-line
        break;

        // UK region
      case 2:
        config.baseURL = `${process.env.VUE_APP_API_URL_UK}/api/${version}`;
        break;

        // Default Singapore region
      default:
        break;
      }
    }
    return config;
  } catch (err) {
    Bugsnag.notify(err);
  }
  return 0;
};
const requestErrorConfig = err => Promise.reject(err);

// create instances for APIv1 and APIv2
const httpv2 = axios.create({
  baseURL: `${process.env.VUE_APP_API_URL || 'https://app.wavecell.dev'}/api/v2`,
  // baseURL: `${process.env.VUE_APP_API_URL}/api/v2`,
});

httpv2.interceptors.response.use(responseSuccessConfig, responseErrorConfig);
httpv2.interceptors.request.use(config => requestSuccessConfig(config, 'v2'), requestErrorConfig);

const httpv1 = axios.create({
  baseURL: `${process.env.VUE_APP_API_URL || 'https://app.wavecell.dev'}/api/v1`,
  // baseURL: `${process.env.VUE_APP_API_URL}/api/v1`,
});

httpv1.interceptors.response.use(responseSuccessConfig, responseErrorConfig);
httpv1.interceptors.request.use(config => requestSuccessConfig(config, 'v1'), requestErrorConfig);

/**
 * Requests a URL, returning a promise.
 *
 * @param  {string} url       The URL we want to request
 * @param  {object} [options] The options we want to pass to "fetch"
 * @return {object}           An object containing either "data" or "err"
 */

const generateMethods = httpversion => ({
  request: options => httpversion(options),
  get: (url, params = {}) => httpversion.get(url, {
    params: {
      ...params,
    },
    paramsSerializer(p) {
      return qs.stringify(p, { indices: false });
    },
  }),
  post: (url, data = {}, config = {}) => httpversion.post(url, data, config),
  put: (url, data = {}) => httpversion({ method: 'put', url, data }),
  delete: (url, data = {}) => httpversion({ method: 'delete', url, data }),
  patch: (url, data = {}) => httpversion({ method: 'patch', url, data }),
});

export default {
  v1: generateMethods(httpv1),
  v2: generateMethods(httpv2),
  post: (url, data = {}, config = {}) => axios.post(url, data, config),
};
