import http from '../../../../utils/http';
import handleErrors from '../../../common/handle-api-errors';

export default {
  verifySSO({ commit }, payload) {
    return new Promise((resolve, reject) => {
      http.v1.post('auth/verifySSO', { ...payload })
        .then((response) => {
          const { ok } = response;

          if (ok) {
            resolve(response);
          } else {
            resolve(response.data);
          }
        })
        .catch((err) => {
          commit('API_ERROR', err, { root: true });
          // eslint-disable-next-line prefer-promise-reject-errors
          reject(err);
        });
    });
  },

  loginSSO({ commit }, payload) {
    return new Promise((resolve, reject) => {
      const qs = `email=${encodeURIComponent(payload.email).replace(/%20/g, '%2B')}`;
      http.v1.get(`saml/login?${qs}`)
        .then(({ status, data }) => {
          if (status === 200) {
            return resolve(data);
          }

          return Promise.reject(data);
        })
        .catch((err) => {
          commit('API_ERROR', err, { root: true });
          // eslint-disable-next-line prefer-promise-reject-errors
          reject(err);
        });
    });
  },
  login({ commit }, payload) {
    return new Promise(async (resolve, reject) => {
      // eslint-disable-next-line no-undef

      if (window.AwsWafIntegration && !window.AwsWafIntegration.hasToken()) {
        await window.AwsWafIntegration.getToken();
      }

      http.v1.post('auth/login', { ...payload }, {
        withCredentials: true,
        crossDomain: true,
      })
        .then(({ status, data, headers }) => {
          if (status && (status === 200 || status === 201)) {
            const { authorization } = headers;

            const rdata = { ...data, authorization };
            return resolve(rdata);
          }

          return Promise.reject(data);
        })
        .catch((err) => {
          commit('API_ERROR', err, { root: true });
          // eslint-disable-next-line prefer-promise-reject-errors
          reject(err);
        });
    });
  },

  async addSubscriptionInfoToCache({ commit }, payload) {
    try {
      const { data } = await http.v2.post(`stigg/accounts/${payload.accountId}`);
      return data;
    } catch (err) {
      commit('API_ERROR', err, { root: true });
      return Promise.reject(err);
    }
  },

  getIP({ commit }) {
    return new Promise((resolve, reject) => {
      fetch('https://ipapi.co/json/', {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      })
        .then(handleErrors)
        .then((data) => {
          resolve(data);
        })
        .catch((err) => {
          commit('API_ERROR', `${err} | tls required for get IP location`, { root: true });
          // eslint-disable-next-line prefer-promise-reject-errors
          reject(err);
        });
    });
  },

  async getAuthUser({ commit }) {
    try {
      const { data } = await http.v1.get('auth/user?rolesVersion=2');
      return data;
    } catch (err) {
      commit('API_ERROR', err, { root: true });
      return Promise.reject(err);
    }
  },

  getDefaultCurrencies({ commit }) {
    return new Promise((resolve, reject) => {
      http.v1.get('signups/default-currencies', {})
        .then((response) => {
          const { data, status } = response;

          if (status === 200) resolve(data);
          else throw new Error('Error fetching default currencies');
        })
        .catch((err) => {
          commit('API_ERROR', err, { root: true });
          // eslint-disable-next-line prefer-promise-reject-errors
          reject(err);
        });
    });
  },
};
