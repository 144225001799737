<template>
  <el-form
    ref="chooseAccount"
    :model="form"
    :rules="rules"
    @submit.native.prevent="handleValidateForm('chooseAccount')"
  >
    <div class="text-center mb-12">
      <Logo />
    </div>
    <h2 class="text-black text-lg mb-2">
      {{ $t('user.choose_account_name') }}
    </h2>
    <p class="text-sm text-grey-darker mb-4">
      {{ $t('user.enter_account_name[0]') }}
      <strong>{{ $t('user.enter_account_name[1]') }}</strong>.
      {{ $t('user.enter_account_name[2]') }}
    </p>

    <!-- Account Name -->
    <div class="block">
      <p class="mb-1 text-sm font-medium text-grey-dark">
        {{ $t('fields.account_name') }}
      </p>
      <el-form-item prop="account">
        <el-input
          v-model="form.account"
          type="text"
          size="small"
          :placeholder="$t('user.your_org_name')"
        />
        <small class="block text-xs leading-6 text-grey-dark">
          {{ $t('user.account_reminder') }}
        </small>
      </el-form-item>
    </div>

    <hr>

    <div class="flex justify-between">
      <h2 class="text-black text-lg mb-2">
        {{ $t('fields.billing_country') }}
        {{ $t('user.terms[2]') }} {{ $t('fields.data_center') }}
      </h2>
      <div
        class="cursor-pointer"
        @click="toggleEdit"
      >
        <img
          v-if="editMode"
          src="../../../../assets/arrow-up.svg"
          alt="ArrowUp"
        >
        <img
          v-else
          src="../../../../assets/pencil.svg"
          alt="Pencil"
        >
      </div>
    </div>
    <p class="text-sm text-grey-darker mb-4">
      {{ $t('user.default_preferences') }}
    </p>

    <div v-if="!editMode">
      <ul class="text-sm pl-5 text-grey-darkest">
        <li>
          <span>
            {{ $t('fields.billing_country') }}: {{ form.countryName }}
          </span>
        </li>
        <li>
          <span>
            {{ $t('fields.data_center') }}: {{ regionName }}
          </span>
        </li>
      </ul>
    </div>
    <div v-else>
      <div class="block">
        <!-- Billing Country -->
        <p class="mb-1 text-sm font-medium text-grey-dark">
          {{ $t('fields.billing_country') }}
        </p>
        <el-form-item>
          <div class="text-sm text-black">
            <el-select
              v-model="form.country"
              class="w-full"
              size="small"
              :placeholder="$t('user.select_billing_country')"
            >
              <el-option
                v-for="item in defaultCurrencies"
                :key="item.Country"
                :label="item.CountryName"
                :value="item.Country"
              >
                <span style="float: left">{{ item.CountryName }}</span>
              </el-option>
            </el-select>
            <small class="block text-xs leading-6 text-grey-dark">
              {{ $t('user.select_billing_country') }}
            </small>
          </div>
        </el-form-item>

        <!-- Data Center Location -->
        <p class="mb-1 text-sm font-medium text-grey-dark">
          {{ $t('fields.data_center_location') }}
        </p>
        <el-form-item>
          <div class="text-sm text-black">
            <el-select
              v-model="form.regionId"
              class="w-full"
              size="small"
            >
              <el-option
                v-for="region in regions"
                :key="region.id"
                :label="region.name"
                :value="region.id"
              >
                <span style="float: left">{{ region.name }}</span>
              </el-option>
            </el-select>
            <small class="block mt-1 text-xs text-grey-dark font-normal text-justify">
              {{ $t('user.data_center_location') }} <a
                href="https://developer.8x8.com/connect/docs/data-center-region"
                target="_blank"
              >{{ $t('user.click_here') }}</a> {{ $t('user.learn_more') }}
            </small>
          </div>
        </el-form-item>
      </div>
    </div>

    <el-form-item>
      <el-button
        :loading="loader"
        :disabled="!form.account"
        type="primary"
        native-type="submit"
        class="w-full mt-4"
      >
        {{ $t('user.create_account') }}
      </el-button>
    </el-form-item>

    <hr>

    <a
      class="flex justify-center text-blue no-underline text-xs inline-block cursor-pointer"
      @click="$emit('back')"
    >
      {{ $t('user.go_back') }}
    </a>
  </el-form>
</template>

<script>
import Logo from '../../../components/Logo.vue';

export default {
  name: 'CreateAccount',

  components: { Logo },

  props: {
    loader: {
      type: Boolean,
      default: false,
    },
    form: {
      type: Object,
      required: true,
    },
    rules: {
      type: Object,
      default: () => {},
    },
    defaultCurrencies: {
      type: Array,
      default: () => [],
    },
    regions: {
      type: Array,
      default: () => [],
    },
  },

  data() {
    return {
      editMode: false,
    };
  },

  computed: {
    regionName() {
      switch (this.form.regionId) {
      case 1: return 'Indonesia';
      case 2: return 'Europe';
      default: return 'Asia';
      }
    },
  },

  methods: {
    toggleEdit() {
      this.editMode = !this.editMode;
    },
    handleValidateForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (!valid) {
          return;
        }

        this.$emit('submit');
      });
    },
  },
};

</script>

<style>
small {
    line-height: 1rem;
}</style>
